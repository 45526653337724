/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import './modernizr-custom.js';

$(async function () {
	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		$('.match-min-height').matchHeight({
			property: 'min-height'
		});
	};

	// Smooth scroll
	function smoothScroll() {
		// Smooth scroll
		$('a[href*=\\#]').click(function () {
			var target = $(this.hash);

			if (!target.length) {
				target = $('[name=' + this.hash.slice(1) + ']');
			}

			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 500);

				return false;
			}
		});
	}

	// Slider
	$('.disciplines').each(async function () {
		const Swiper = (await import('swiper')).default;

		new Swiper(this, {
			grabCursor: true,
			loop: true,
			spaceBetween: 40,
			slidesPerView: 3,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			breakpoints: {
				1024: {
					slidesPerView: 2,
				},
				640: {
					slidesPerView: 1,
				}
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			on: {
				init: function () {
					if ($('.match-min-height')[0]) {
						matchHeights();
					}
					smoothScroll();
				}
			}
		});
	});

	// Object fit fallback
	if (!Modernizr.objectfit) {
		$('.object-fit').each(function () {
			var element = $(this);
			var image = element.find('img');

			element.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')', 'background-position': 'center' });
			element.find('picture').remove();
			image.remove();
		});
	}

	// Responsive menu
	$('.toggler').click(function () {
		var toggler = $('.toggler')
		var responsiveNav = $('.responsive-nav');

		$(document.body).toggleClass('menu-open');
		responsiveNav.toggleClass('-collapsed');
		toggler.toggleClass('is-active');
	});

	// Jump to submitted form
	$('.application-from:has(form)').each(function () {
		var $this = $(this),
			form = $this.find('form'),
			anchor = $this.find('.anchor').attr('name');

		form.attr('action', form.attr('action') + '#' + anchor);
	});

	// Smooth scroll
	smoothScroll();
});